<script>
	import {getInfoPageById} from "../../functions/api/content";
	import SmartImage from "../../components/smart-image";

	export default {
		components: {SmartImage},
		data() {
			return {
				loading: false,
				data: {
					title: '',
					lead: ''
				}
			}
		},
		created() {
			this.refreshData(this.$route.params.id);
		},
		watch: {
			'$route.params.id'() {
				this.refreshData(this.$route.params.id);
			}
		},
		methods: {
			refreshData(pageId) {
				this.loading = true;
				return getInfoPageById(this.$store.state.userToken, pageId)
					.then(res => this.data = res)
					.catch(err => console.error(err))
					.then(() => this.loading = false)
			},
			addLineBreaks(text) {
				return text.replace(/\n/g, '<br/>');
			}
		}
	}
</script>
<template>
	<div class="container" :class="{'from-url': data.url && data.url !== ''}">
		<template v-if="data.components && data.components.length > 0">
			<section class="main">
				<h1>{{data.title}}</h1>
				<div class="lead-text" v-if="data.lead">
					<h3 v-html="addLineBreaks(data.lead)"></h3>
				</div>
				<smart-image v-if="data.image_key" :img_key="data.image_key" size="large"></smart-image>
			</section>
			<section class="components">
			<div
					v-for="(component, index) in data.components"
					:key="index"
					class="component"
					:class="component.type"
			>
				<template v-if="component.type === 'title'">
					<h2>{{component.text}}</h2>
				</template>

				<template v-if="component.type === 'text'">
					<vue-simple-markdown :source="component.text"></vue-simple-markdown>
				</template>

				<template v-if="component.type === 'image'">
					<smart-image :img_key="component.image_key" size="large"></smart-image>
				</template>

				<template v-if="component.type === 'video'">
					<div class="video-container">
						<iframe
								:src="component.url"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen></iframe>
					</div>
				</template>

				<template v-if="component.type === 'link'">
					<a :href="component.url" target="_blank">
						<div>
							{{component.text}}
						</div>
						<smart-image v-if="component.image_key" :img_key="component.image_key" size="large"></smart-image>
					</a>
				</template>

			</div>
		</section>
		</template>
		<section v-else-if="data.url && data.url !== ''" class="outer-link">
			<iframe :src="data.url" frameborder="none"></iframe>
		</section>
	</div>
</template>
<style scoped>
	#info-page.from-url {
		height: calc(100vh - 140px);
		display: flex;
		flex-direction: column;
	}
	section.main {
		margin-bottom: 40px;
	}
	section.main > h1 {
		margin-bottom: 40px;
	}
	.lead-text {
		border-left: 4px solid var(--theme-color-3);
		padding-left: 26px;
		margin-bottom: 40px;
		line-height: 26px;
	}
	.component {
		margin-bottom: 20px;
	}
	.component.link {
		color: var(--theme-color-3);
	}
	.smart-image {
		border-radius: 5px;
		box-shadow: 0 10px 20px #00000033;
		margin: 20px 0;
	}
	.video-container {
		width: 100%;
		padding-top: 56.25%; /* 16:9 Aspect Ratio */
		position: relative;
	}
	.video-container iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		box-shadow: 0 10px 20px #00000033;
	}

	section.outer-link {
		flex: 1;
		margin: -20px -20px 0;
	}
	section.outer-link iframe {
		border: none;
		width: 100%;
		height: 100%;
	}
</style>